(function() {
    document.getElementById('mobmenu').onclick = function() {
        var list = document.getElementById('moblist');
        var className = ' ' + list.className + ' ';
        if ( ~className.indexOf(' open ') ) {
            list.className = className.replace(' open ', ' ');
        } else {
            list.className += ' open';
        }
    }
})();